import React, { type ReactNode } from 'react';
import { useId } from 'react-aria';
import { Minus, Plus } from '@hungryroot/icons';
import styles from './Accordion.module.css';

interface Props {
  title: ReactNode;
  children: ReactNode;
  className?: string;
  isExpanded: boolean;
  buttonClassName?: string;
  bodyClassName?: string;
  headingClassName?: string;
  onToggle: (data: { expanded: boolean }) => void;
}

export function Accordion({
  onToggle,
  bodyClassName,
  headingClassName,
  buttonClassName,
  isExpanded,
  title,
  children,
  className,
}: Props) {
  const id = useId();
  const sectionId = useId();
  const icon = isExpanded === true ? <Minus /> : <Plus />;

  return (
    <div className={className}>
      <header id={id} className={headingClassName}>
        <button
          className={`${styles.button} ${buttonClassName}`}
          aria-controls={sectionId}
          aria-expanded={isExpanded}
          onClick={() => onToggle({ expanded: !isExpanded })}
        >
          {title}
          <span className={styles.icon}>{icon}</span>
        </button>
      </header>
      {isExpanded === true && (
        <section
          className={`${styles.body} ${bodyClassName}`}
          id={sectionId}
          aria-labelledby={id}
        >
          {children}
        </section>
      )}
    </div>
  );
}
