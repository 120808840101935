import React, { type ReactNode } from 'react';
import {
  Heading,
  HeadingVariant,
  Paragraph,
  ParagraphVariant,
} from '@hungryroot/ramen';

import styles from './TasteAndNeedsCard.module.css';

export const tastesAndNeedsCta = styles['quiz-button'];

interface Props {
  image: ReactNode;
  header: {
    text: string;
    icon: ReactNode;
  };
  body: ReactNode;
  recipesSummary?: {
    text: string;
    illustration: ReactNode;
    caption: string;
  };
  resumeQuizButton: ReactNode;
}

export function TasteAndNeedsCard(props: Props) {
  const {
    image,
    body,
    header: { text: headerText, icon },
    recipesSummary,
    resumeQuizButton,
  } = props;
  const {
    text: summaryText,
    illustration: summaryIllustration,
    caption: summaryCaption,
  } = recipesSummary || {};

  return (
    <div className={styles.container}>
      <figure className={styles.figure}>
        <div className={styles.image}>{image}</div>
        <figcaption className={styles.figcaption}>
          <Heading className={styles.header} variant={HeadingVariant.H2}>
            {icon} {headerText}
          </Heading>
          {body}
          {summaryText && summaryCaption && summaryIllustration && (
            <section className={styles.summary}>
              <div className={styles['summary-img']}>{summaryIllustration}</div>
              <div>
                <Heading
                  className={styles['summary-header']}
                  variant={HeadingVariant.H3}
                >
                  {summaryText}
                </Heading>
                <Paragraph variant={ParagraphVariant.Body}>
                  {summaryCaption}
                </Paragraph>
              </div>
            </section>
          )}
          <div className={styles.button}>{resumeQuizButton}</div>
        </figcaption>
      </figure>
    </div>
  );
}
